import { requestApi } from '@/utils/axios'
import { LoginReq, LoginRes } from './data'

/**
 * 调用登录接口
 * @param params 
 * @returns 
 */
 const requestLogin = async (params: LoginReq) => {
	return await requestApi<LoginReq, LoginRes>({
		url: `/api/syn/videoDataPlat/openUser/login`,
		data: {
			...params
		},
	})
}

export {
	requestLogin
}