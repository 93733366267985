import { requestApi } from '@/utils/axios'

/**
 * 调用登录接口
 * @param params 
 * @returns 
 */
 const requestGetTaskInfo = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/task/info`,  
		data: {
			...params
		},
		method: 'GET'
	})
}
/**
 * 获取产品列表
 * @param params 
 * @returns 
 */
 const requestGetVideoList = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/task/videolist`,  
		data: {
			...params
		},
	})
}

/**
 * 获取品牌的日期数据
 * @param params 
 * @returns 
 */
 const requestGetTaskDayDataList = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/task/dayDataList`,  
		data: {
			...params
		},
	})
}

/**
 * 获取视频在第三方平台的地址
 * @param params 
 * @returns 
 */
 const requestGetVideoSharePlatPlayUrl = async (params: any) => {   ///api/syn/videoDataPlat/openUser/task/videoSharePlatPlayUrl
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/task/videoSharePlatPlayUrl`,  
		data: {
			...params
		},
		method: 'GET'
	})
}

/**
 * 导出作品列表
 * @param params 
 * @returns 
 */
 const requestDownloadVideoList = async (params: any) => {   //​
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/task/downloadVideolist`,  
		data: {
			...params
		},
		method: 'POST',
		responseType: 'blob'
	})
}



export {
	requestGetTaskInfo,
	requestGetVideoList,
	requestGetTaskDayDataList,
	requestGetVideoSharePlatPlayUrl,
	requestDownloadVideoList
}