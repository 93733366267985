import { requestApi } from '@/utils/axios'

/**
 * 调用登录接口
 * @param params 
 * @returns 
 */
 const requestGetProductInfo = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/product/info`,  
		data: {
			...params
		},
		method: 'GET'
	})
}


/**
 * 获取产品列表
 * @param params 
 * @returns 
 */
 const requestGetTaskList = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/task/list`,  
		data: {
			...params
		},
		method: 'GET'
	})
}

export {
	requestGetProductInfo,
	requestGetTaskList
}