import { requestApi } from '@/utils/axios'
import { GetProductInfoReq, GetUserBrandListReq } from './data'

/**
 * 调用登录接口
 * @param params 
 * @returns 
 */
 const requestGetProductInfo = async (params: GetProductInfoReq) => {
	return await requestApi<GetProductInfoReq, any>({ 
		url: `/api/syn/videoDataPlat/openUser/product/info`,  
		data: {
			...params
		},
	})
}

/**
 * 获取品牌列表
 * @param params 
 * @returns 
 */
const requestGetUserBrandList = async (params: GetUserBrandListReq) => {
	return await requestApi<GetUserBrandListReq, any>({ 
		url: `/api/syn/videoDataPlat/openUser/brand/list`,  
		data: {
			...params
		},
	})
}

/**
 * 获取品牌详情
 * @param params 
 * @returns 
 */
const requestGetBrandInfo = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/brand/info`, 
		data: {
			...params
		},
		method: 'GET'
	})
}

/**
 * 获取产品列表
 * @param params 
 * @returns 
 */
 const requestGetProductList = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/product/list`,  
		data: {
			...params
		}
	})
}

/**
 * 获取品牌的日期数据
 * @param params 
 * @returns 
 */
 const requestGetDayDataList = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/dayDataList`,  
		data: {
			...params
		},
	})
}

const requestGetUserInfo = async (params: any) => {
	return await requestApi<any, any>({ 
		url: `/api/syn/videoDataPlat/openUser/userInfo`,  
		data: {
			...params
		},
		method: 'GET'
	})
}



export {
	requestGetProductInfo,
	requestGetUserBrandList,
	requestGetBrandInfo,
	requestGetProductList,
	requestGetDayDataList,
	requestGetUserInfo
}