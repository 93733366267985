import React from 'react';
import './index.scss'
import { HomeProps, HomeState } from './data'
import logoImage from '@/assets/main/index/logo.png'
import defaultAvatarImage from '@/assets/main/index/default_avatar.png'
import { getUserInfoStorage, removeUserInfoStorage } from '@/utils/storage'



export default class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps = {}) {
        super(props);
        this.state = {
            userInfo: getUserInfoStorage()
        }
    }

    componentDidMount() {
        
    }

    /**
	 * 点击退出按钮时触发
	 * @param 
	 * @return
	 */
    handleQuit() {
        removeUserInfoStorage()
        this.props.history?.push({pathname: '/'})
    }


    render() {
        return (
            <div className='layout'>
                <div className='layout-header'>
                    <img className='layout-header-logo' src={logoImage} alt="" />
                    <span className='layout-header-quit' onClick={()=> { this.handleQuit() }}>退出</span>
                    <span className='layout-header-userName'>{ this.state.userInfo.name }</span>
                    <img className='layout-header-avatar' src={defaultAvatarImage} alt="" />
                </div>
                <div className='layout-content'>
                    {
                        this.props.children
                    }
                </div>
            </div>
        )
    }
}