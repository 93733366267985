import { CustomerTaskProps, CustomerTaskState } from './data'
import React, { Component } from 'react';
import './task.scss'
import moment, { Moment } from 'moment'
import { message, Pagination, Tooltip, Select } from 'antd';
import { requestGetVideoList, requestGetTaskDayDataList, requestGetTaskInfo, requestGetVideoSharePlatPlayUrl, requestDownloadVideoList } from './service'
import ReactEcharts from "echarts-for-react"
import { replaceNumbeToW, replaceDateFormYYYYMMDD, throttleFnc } from '@/utils/common'
import numberWorksImage from '@/assets/main/customer/home/number_works.png'
import numberPlayImage from '@/assets/main/customer/home/number_play.png'
import playbackPlatformImage from '@/assets/main/customer/home/playback_platform.png'
import numberParticipantsImage from '@/assets/main/customer/task/number_participants.png'
import worksCommentImage from '@/assets/main/customer/task/works_comment.png'
import worksGiveImage from '@/assets/main/customer/task/works_give.png'
import worksPlayImage from '@/assets/main/customer/task/works_play.png'
import nworksShareImage from '@/assets/main/customer/task/works_share.png'
import RangePicker from '@/components/rangerPicker/rangePicker'
import defaultLogoImg from '@/assets/normal/brand_default_logo.png'
import worksOccupationBitmapImg from '@/assets/normal/works_occupation_bitmap.png'
import exportPng from '@/assets/main/task/export.png'
import Empty from '@/components/empty/empty'
import Spin from '@/components/spin/spin'
import Back from '@/components/back/back'
import fileDownload from 'js-file-download'

const { Option } = Select


export default class CustomerTaskComponent extends Component<CustomerTaskProps, CustomerTaskState> {
	constructor(props: CustomerTaskProps = {

	}) {
		super(props);
		this.state = {
			taskId: this.props.location?.state.taskId,
			productId: this.props.location?.state.productId,
			dates: [
				moment(new Date()).subtract(7, 'd'),
				moment(new Date()).subtract(1, 'd')
			],
			workList: [1],
			taskInfo: {},
			dayDataList: [],
			echartsOption: {},
			pageInfo: {
				pageSize: 12,
				page: 1,
				totalPage: 0,
				total: 0
			},
			isLoad: true,
			abnormalStatus: ''
		}
	}

	componentDidMount() {
		this.getTaskInfo()
		this.getTaskDayDataList()
	}


	/**
	 * 获取任务详情
	 * @param 
	 * @return
	 */
	getTaskInfo() {
		requestGetTaskInfo({
			taskId: this.state.taskId,
			productId: this.state.productId,
			appId: "EZ0jRe9W"
		}).then((res) => {
			this.getVideoList(res.Data.appId)
			res.Data.attendUserNum = res.Data.attendUserNum
			res.Data.videoNum = res.Data.videoNum
			res.Data.playNum = res.Data.playNum
			this.setState({
				taskInfo: res.Data
			})
		})
	}

	/**
	 * 获取品牌下每天的数据
	 * @param 
	 * @return
	 */
	getTaskDayDataList() {
		if (this.state.taskId) {
			const dates = this.state.dates
			const params: any = {
				taskId: this.state.taskId,
				productId: this.state.productId,
				appId: "EZ0jRe9W"
			}
			if (dates[0]) {
				params.startDate = moment(dates[0]).format('YYYYMMDD')
			}
			if (dates[1]) {
				params.endDate = moment(dates[1]).format('YYYYMMDD')
			}
			requestGetTaskDayDataList({
				...params
			}).then((res) => {
				const dayDataList: any[] = res.Data.data
				let xAxisData: any[] = [], dySeriesData: any[] = [], ksSeriesData: any[] = [];
				(dayDataList as any[]).map((dayDataItem: any, dayDataItemKey: number) => {
					xAxisData.push(replaceDateFormYYYYMMDD(dayDataItem.createdDate, '/'))
					dySeriesData.push(dayDataItem.dyPlayNum)
					ksSeriesData.push(dayDataItem.ksPlayNum)
				})
				this.setState({
					echartsOption: {
						xAxis: {
							type: 'category',
							data: xAxisData,
							axisLabel: {
								show: true,
								color: '#666666',
								fontFamily: 'Arial'
							},
							axisLine: {
								lineStyle: {
									color: '#ECECEC'
								}
							}
						},
						yAxis: {
							type: 'value',
							minInterval: 1
						},
						series: [
							{
								name: '抖音',
								data: dySeriesData,
								type: 'line',
								smooth: true,
								itemStyle: {
									color: '#EF8944',
									lineStyle: {
										color: '#EF8944'
									}
								},
							},
							{
								name: '快手',
								data: ksSeriesData,
								type: 'line',
								smooth: true,
								itemStyle: {
									color: '#0A88ED',
									lineStyle: {
										color: '#0A88ED'
									}
								},
							}
						],
						grid: {
							left: '25px',
							right: 0,
							top: '50px',
							bottom: '30px'
						},
						tooltip: {
							trigger: 'axis',
						},
						legend: {
							data: ['抖音', '快手'],
							top: 0,
							right: 0,
							itemWidth: 40,
							itemHeight: 14
						},
					}
				})
			})
		}
	}

	/**
	 * 获取任务下视频列表
	 * @param 
	 * @return
	 */
	getVideoList(appId?: string) {
		this.setState({
			isLoad: true
		}, () => {
			requestGetVideoList({
				taskId: this.state.taskId,
				appId: "EZ0jRe9W",
				pageSize: this.state.pageInfo.pageSize,
				current: this.state.pageInfo.page,
				abnormalStatus: this.state.abnormalStatus 
			}).then((res) => {
				const pageInfo = { ...this.state.pageInfo }
				pageInfo.total = res.Data.total
				this.setState({
					workList: res.Data.data,
					pageInfo: pageInfo,
					isLoad: false
				})
			}).catch(() => {
				this.setState({
					workList: [],
					isLoad: false
				})
			})
		})
	}

	/**
	 * 处理时间选择内容变化
	 * @param 
	 * @return
	 */
	handleChange(event: Moment | null, type: "startDate" | "endDate") {
		const dates = this.state.dates
		if (type === 'startDate') {
			dates[0] = event as any
		} else if (type === 'endDate') {
			dates[1] = event as any
		}
		this.getTaskDayDataList()
		// if (dates[0] && dates[1]) {
		// 	this.getTaskDayDataList()
		// } 
		this.setState({
			dates: dates
		})
	}

	/**
	 * 处理页码改变的回调
	 * @param 
	 * @return
	 */
	handlePaginationChange(page: number, pageSize?: number) {
		const pageInfo = { ...this.state.pageInfo }
		pageInfo.page = page
		this.setState({
			pageInfo: pageInfo
		}, () => {
			this.getVideoList()
		})
	}

	/**
	 * 处理点击产品
	 * @param 
	 * @return
	 */
	handleViewVideo(workItem: any) {
		requestGetVideoSharePlatPlayUrl({
			videoRecordId: workItem.id
		}).then((res) => {
			window.open(res.Data, "_blank");
		})
		//
	}


	/**
	 * 处理点击导出
	 * @param 
	 * @return
	 */
	handleExport = throttleFnc((callback: Function) => {
		message.loading('系统处理中...')
		requestDownloadVideoList({
			appId: "EZ0jRe9W",
			taskId: this.state.taskId,
			abnormalStatus: this.state.abnormalStatus 
		}).then((res) => {
			message.destroy()
			const { data } = res as any, fileName = decodeURI((res as any).headers['content-disposition']).substring(20)
			fileDownload(data, fileName)
			callback()
		}).catch(() => {
			message.destroy()
			callback()
		})
	})

	/**
	 * 处理返回上一页
	 * @param 
	 * @return
	 */
	handleBack() {
		this.props.history?.goBack()
	}

	/**
	 * 渲染产品项
	 * @param 
	 * @return
	 */
	renderProductItem(workItem: any, productKey: number) {
		return <div className='customerWork-item' key={productKey}>
			<div className='customerWork-item-content'>
				<img src={workItem.videoOssFile ? `${workItem.videoOssFile}?x-oss-process=video/snapshot,t_10000,m_fast` : worksOccupationBitmapImg} alt="" />
				<div className='customerWork-item-textBox'>
					<p className='customerWork-item-title text_ellipsis'>{workItem.videoTitle}</p>
					<div className='customerWork-item-center'>
						<div>昵称：{workItem.userNickName}</div>
						<div>粉丝：{workItem.fansNum}</div>
						<div>发布的平台： {workItem.sharePlatName}</div>
						<div>发布时间：{replaceDateFormYYYYMMDD(workItem.createdDate || '', ['年', '月', '日'])}</div>
						<div>数据更新时间：{workItem.fetchTimestamp > 0 ? moment(workItem.fetchTimestamp).format('YYYY年MM月DD日') : replaceDateFormYYYYMMDD(workItem.createdDate || '', ['年', '月', '日'])}</div>
					</div>
				</div>
				<div className='customerWork-item-bottom'>
					<div className='customerWork-item-bottom-count'>
						<div className='customerWork-item-bottom-count-item'>
							<img src={worksPlayImage} alt="" />
							<span>{replaceNumbeToW(workItem.playNum)}</span>
						</div>
						<div className='customerWork-item-bottom-count-item'>
							<img src={worksGiveImage} alt="" />
							<span>{replaceNumbeToW(workItem.likeNum)}</span>
						</div>
						<div className='customerWork-item-bottom-count-item'>
							<img src={worksCommentImage} alt="" />
							<span>{replaceNumbeToW(workItem.commentNum)}</span>
						</div>
						<div className='customerWork-item-bottom-count-item'>
							<img src={nworksShareImage} alt="" />
							<span>{replaceNumbeToW(workItem.forwardNum)}</span>
						</div>
					</div>
					<div className='customerWork-item-bottom-goView' onClick={() => { this.handleViewVideo(workItem) }}>去看看</div>
				</div>

				{
					workItem.abnormalStatus == 2 ? <div className='customerWork-item-abnormal'>异常</div> : <div></div>
				}
			</div>
		</div>
	}

	/**
	 * 异常状态改变时触发
	 * @param 
	 * @return
	 */
	handleChangeAbnormalStatus(val: string) {
		this.setState({
			abnormalStatus: val
		}, () => {
			this.getVideoList()
		})
	}

	render() {
		return (
			<div className="customerTask">
				<Back onClick={() => { this.handleBack() }}></Back>
				<div className='customerTask-title'>
					<div className='customerTask-title-data'>
						<div className='customerTask-title-data-list'>
							<div className='customerTask-title-data-item'>
								<div className='customerTask-title-data-item-content'>
									<img src={numberParticipantsImage} alt="" />
									<div className='customerTask-title-data-item-text'>
										<p className='text_ellipsis'>{this.state.taskInfo.attendUserNum}</p>
										<p className='text_ellipsis'>累计参与人数</p>
									</div>
								</div>
							</div>
							<div className='customerTask-title-data-item'>
								<div className='customerTask-title-data-item-content'>
									<img src={numberWorksImage} alt="" />
									<Tooltip title={`异常: ${replaceNumbeToW(this.state.taskInfo.exceptionPlayNum)}`}>
										<div className='customerTask-title-data-item-text'>
											<p className='text_ellipsis'>{this.state.taskInfo.videoNum}</p>

											<p className='text_ellipsis'>累计产生作品数</p>
										</div>
									</Tooltip>
								</div>
							</div>
							<div className='customerTask-title-data-item'>
								<div className='customerTask-title-data-item-content'>
									<img src={numberPlayImage} alt="" />
									<Tooltip title={`异常: ${replaceNumbeToW(this.state.taskInfo.exceptionVideoNum)}`}>
										<div className='customerTask-title-data-item-text'>
											<p className='text_ellipsis'>{replaceNumbeToW(this.state.taskInfo.playNum)}</p>
											<p className='text_ellipsis'>累计播放量</p>
										</div>
									</Tooltip>
								</div>
							</div>
							<div className='customerTask-title-data-item'>
								<div className='customerTask-title-data-item-content'>
									<img src={playbackPlatformImage} alt="" />
									<div className='customerTask-title-data-item-text'>
										<p className='text_ellipsis' style={{ fontSize: '24px' }}>抖音、快手</p>
										<p className='text_ellipsis'>作品流量平台</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='customerTask-title-company'>
						<img src={this.state.taskInfo.taskCoverImg || defaultLogoImg} alt="" />
						<p className='text_ellipsis'>
							{this.state.taskInfo.taskName}
							<span>{moment(this.state.taskInfo.taskStartTime).format('MM月DD日')} - {moment(this.state.taskInfo.taskEndTime).format('MM月DD日')}</span>
						</p>
					</div>
				</div>
				<div className='customerTask-charts'>
					<div className='customerTask-charts-title'>
						<p>播放量</p>
						<div className='customerTask-charts-title-search'>
							<RangePicker label='时间选择' allowClear={false} value={this.state.dates} onChange={(event, type) => { this.handleChange(event, type) }}></RangePicker>
						</div>
					</div>
					<div ref="customerTask-charts" id='customerTask-charts' className='customerTask-charts-content'>
						<ReactEcharts
							option={this.state.echartsOption}
							//onEvents={onEvents}
							style={{ padding: '12px 24px 24px', height: '100%' }}
						/>
					</div>
				</div>

				<div className='card'>
					<p className='card-title'>作品列表</p>
					<div className='card-export' onClick={() => { this.handleExport() }}>
						<img src={exportPng} alt="" />
						<span>导出</span>
					</div>
					<div className='card-srearch'>
						<Select value={ this.state.abnormalStatus } onChange={ (val: any)=> { this.handleChangeAbnormalStatus(val) } }>
							<Option value="">全部</Option>
							{/* <Option value={1}>正常</Option> */}
							<Option value={2}>异常</Option>
						</Select>
					</div>
					<div className='customerWork-list'>
						{
							this.state.isLoad ? <Spin></Spin> : (
								this.state.workList.length ? this.state.workList.map((workItem, productKey) => {
									return this.renderProductItem(workItem, productKey)
								}) : <Empty></Empty>
							)
						}
					</div>

					{this.state.workList.length && !this.state.isLoad ?
						<div className='pagination'>
							<Pagination style={{ float: 'right' }} pageSize={this.state.pageInfo.pageSize} showQuickJumper current={this.state.pageInfo.page} total={this.state.pageInfo.total} onChange={(page, pageSize) => { this.handlePaginationChange(page, pageSize) }} />
						</div> : ''
					}
				</div>
			</div>
		)
	}
}