import { BackProps, BackState } from './data'
import React, { Component } from 'react';
import backImage from '@/components/assets/image/back/back.png'
import './back.scss'

export default class BackComponent extends Component<BackProps, BackState> {
	constructor(props: BackProps = {

	}) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {

	}

	/**
	 * 点击back按钮时触发
	 * @param 
	 * @return
	 */
	handleBack(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		if (this.props.onClick && typeof this.props.onClick === 'function') {
			this.props.onClick(event)
		}
	}




	render() {
		return (
			<div className='dz-back' onClick={(event) => { this.handleBack(event) }}>
				<img src={backImage} alt="" />
				<span>返回上一页</span>
			</div>
		)
	}
}