import { CustomerProductProps, CustomerProductState } from './data'
import React, { Component } from 'react';
import './product.scss'
import { Pagination } from 'antd';
import { requestGetTaskList, requestGetProductInfo } from './service'
import { replaceNumbeToW } from '@/utils/common'
import numberWorksImage from '@/assets/main/customer/home/number_works.png'
import numberPlayImage from '@/assets/main/customer/home/number_play.png'
import playbackPlatformImage from '@/assets/main/customer/home/playback_platform.png'
import defaultLogoImg from '@/assets/normal/brand_default_logo.png'
import Empty from '@/components/empty/empty'
import Spin from '@/components/spin/spin'
import Back from '@/components/back/back'

export default class CustomerProductComponent extends Component<CustomerProductProps, CustomerProductState> {
	constructor(props: CustomerProductProps = {

	}) {
		super(props);
		this.state = {
			productId: this.props.location?.state.productId,
			brandId: this.props.location?.state.brandId,
			taskList: [],
			productInfo: {},
			dayDataList: [],
			pageInfo: {
				pageSize: 12,
				page: 1,
				totalPage: 0,
				total: 0
			},
			isLoad: true
		}
	}


	componentDidMount() {
		this.getProductInfo()
		this.getTaskList()
	}

	/**
	 * 获取产品详情
	 * @param 
	 * @return
	 */
	getProductInfo() {
		requestGetProductInfo({
			productId: this.state.productId,
			//brandId: this.state.brandId,
		}).then((res) => {
			res.Data.videoNum = res.Data.videoNum
			res.Data.playNum = res.Data.playNum
			this.setState({
				productInfo: res.Data
			})
		})
	}


	/**
	 * 获取品牌下的产品列表
	 * @param 
	 * @return
	 */
	getTaskList() {
		this.setState({
			isLoad: true
		}, () => {
			requestGetTaskList({
				productId: this.state.productId,
				pageSize: this.state.pageInfo.pageSize,
				current: this.state.pageInfo.page
			}).then((res) => {
				const pageInfo = { ...this.state.pageInfo }
				pageInfo.total = res.Data.total
				this.setState({
					taskList: res.Data.data,
					pageInfo: pageInfo,
					isLoad: false
				})
			}).catch(() => {
				this.setState({
					taskList: [],
					isLoad: false
				})
			})
		})
	}

	/**
	 * 处理点击产品
	 * @param 
	 * @return
	 */
	handleClickTask(taskItem: any) {
		this.props.history?.push({
			pathname: '/main/customer/task', state: {
				taskId: taskItem.taskId,
				productId: this.state.productId
			}
		})
	}

	/**
	 * 处理页码改变的回调
	 * @param 
	 * @return
	 */
	handlePaginationChange(page: number, pageSize?: number) {
		const pageInfo = { ...this.state.pageInfo }
		pageInfo.page = page
		this.setState({
			pageInfo: pageInfo
		}, () => {
			this.getTaskList()
		})
	}



	/**
	 * 处理返回上一页
	 * @param 
	 * @return
	 */
	handleBack() {
		this.props.history?.goBack()
	}

	/**
	 * 渲染任务项
	 * @param 
	 * @return
	 */
	renderTaskItem(taskItem: any, taskKey: number) {
		return <div className='customerTask-item' key={taskKey}>
			<div className='customerTask-item-content' onClick={() => { this.handleClickTask(taskItem) }}>
				<img src={taskItem.taskCoverImg} alt="" />
				<div className='customerTask-item-textBox'>
					<p className='customerTask-item-title text_ellipsis'>{taskItem.taskName || '饮人民小酒，品百味人生'}</p>
					<div className='customerTask-item-count'>
						<div>
							<div className='customerTask-item-count-content'>
								<p>{taskItem.videoNum}</p>
								<p>作品量</p>
							</div>
						</div>
						<div>
							<div className='customerTask-item-count-content'>
								<p>{replaceNumbeToW(taskItem.playNum)}</p>
								<p>播放量</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}

	render() {
		return (
			<div className="customerProduct">
				<Back onClick={() => { this.handleBack() }}></Back>
				<div className='customerProduct-title'>
					<div className='customerProduct-title-data'>
						<div className='customerProduct-title-data-list'>
							<div className='customerProduct-title-data-item'>
								<div className='customerProduct-title-data-item-content'>
									<img src={numberWorksImage} alt="" />
									<div className='customerProduct-title-data-item-text'>
										<p>{this.state.productInfo.videoNum}</p>
										<p>累计产生作品数</p>
									</div>
								</div>
							</div>
							<div className='customerProduct-title-data-item'>
								<div className='customerProduct-title-data-item-content'>
									<img src={numberPlayImage} alt="" />
									<div className='customerProduct-title-data-item-text'>
										<p>{replaceNumbeToW(this.state.productInfo.playNum)}</p>
										<p>累计播放量</p>
									</div>
								</div>
							</div>
							<div className='customerProduct-title-data-item'>
								<div className='customerProduct-title-data-item-content'>
									<img src={playbackPlatformImage} alt="" />
									<div className='customerProduct-title-data-item-text'>
										<p style={{ fontSize: '24px' }}>抖音、快手</p>
										<p>作品流量平台</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='customerProduct-title-company'>
						<img src={this.state.productInfo.productCoverImg || defaultLogoImg} alt="" />
						<p>{this.state.productInfo.productName}</p>
					</div>
				</div>

				<div className='card'>
					<p className='card-title'>任务列表</p>
					<div className='customerTask-list'>
						{
							this.state.isLoad ? <Spin></Spin> : (this.state.taskList.length ?
								this.state.taskList.map((taskItem, taskKey) => {
									return this.renderTaskItem(taskItem, taskKey)
								}) : <Empty></Empty>)
						}
					</div>

					{
						this.state.taskList.length && !this.state.isLoad ? <div className='pagination'>
							<Pagination style={{ float: 'right' }} pageSize={this.state.pageInfo.pageSize} showQuickJumper current={this.state.pageInfo.page} total={this.state.pageInfo.total} onChange={(page, pageSize) => { this.handlePaginationChange(page, pageSize) }} />
						</div> : ''
					}
				</div>
			</div>
		)
	}
}