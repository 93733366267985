import { CustomerHomeProps, CustomerHomeState } from './data'
import React, { Component } from 'react';
import './home.scss'
import moment, { Moment } from 'moment'
import { message, Pagination } from 'antd';
import { requestGetUserBrandList, requestGetBrandInfo, requestGetProductList, requestGetDayDataList, requestGetUserInfo } from './service'
//import echarts from 'echarts'
import ReactEcharts from "echarts-for-react"
import { replaceNumbeToW, replaceDateFormYYYYMMDD } from '@/utils/common'
import numberWorksImage from '@/assets/main/customer/home/number_works.png'
import numberPlayImage from '@/assets/main/customer/home/number_play.png'
import playbackPlatformImage from '@/assets/main/customer/home/playback_platform.png'
import defaultLogoImg from '@/assets/normal/brand_default_logo.png'
import commonOccupationBitmapImg from '@/assets/normal/common_occupation_bitmap.png'
import RangePicker from '@/components/rangerPicker/rangePicker'
import { getTokenStorage, getUserInfoStorage } from '@/utils/storage';
import Empty from '@/components/empty/empty'
import Spin from '@/components/spin/spin'

export default class CustomerHomeComponent extends Component<CustomerHomeProps, CustomerHomeState> {
	constructor(props: CustomerHomeProps = {

	}) {
		super(props);
		this.state = {
			dates: [
				moment(new Date()).subtract(7, 'd'),
				moment(new Date()).subtract(1, 'd')
			],
			productList: [],
			brandInfo: {},
			dayDataList: [],
			echartsOption: {},
			pageInfo: {
				pageSize: 12,
				page: 1,
				totalPage: 0,
				total: 0
			},
			isLoad: true,
			userInfo: {},
		}
	}

	componentDidMount() {
		//this.getUserBrandList()
		this.getProductList()
		this.getDayDataList()
		this.getUserInfo()
	}

	/**
	 * 获取用户下的品牌列表
	 * @param 
	 * @return
	 */
	getUserBrandList() {
		requestGetUserBrandList({
			token: getTokenStorage(),
			userId: getUserInfoStorage().userId
		}).then((res) => {
			if (res.Data.total > 0) {
				const brandItem = res.Data.data[0]
				this.setState({
					brandInfo: brandItem
				})
				this.getProductList()
				this.getDayDataList()
			} else {
				this.setState({
					brandInfo: {},
					isLoad: false
				})
			}
		})
	}

	/**
	 * 获取用户信息
	 * @param 
	 * @return
	 */
	getUserInfo() {
		requestGetUserInfo({}).then((res) => {
			res.Data.videoNum = res.Data.videoNum
			res.Data.playNum = res.Data.playNum
			this.setState({
				userInfo: res.Data
			})
		})
	}

	/**
	 * 获取品牌下每天的数据
	 * @param 
	 * @return
	 */
	getDayDataList() {
		const dates = this.state.dates
		requestGetDayDataList({
			userId: getUserInfoStorage().userId,
			appId: 'czZeL1vr',
			brandId: 0,
			//brandId: brandId || this.state.brandInfo.brandId,
			startDate: moment(dates[0]).format('YYYYMMDD'),
			endDate: moment(dates[1]).format('YYYYMMDD')
		}).then((res) => {
			const dayDataList: any[] = res.Data.data
			let xAxisData: any[] = [], seriesData: any[] = [];
			(dayDataList as any[]).map((dayDataItem: any, dayDataItemKey: number) => {
				xAxisData.push(replaceDateFormYYYYMMDD(dayDataItem.createdDate, '/'))
				seriesData.push(dayDataItem.playNum)
			})
			this.setState({
				echartsOption: {
					xAxis: {
						type: 'category',
						data: xAxisData,
						axisLabel: {
							show: true,
							color: '#666666',
							fontFamily: 'Arial'
						},
						axisLine: {
							lineStyle: {
								color: '#ECECEC'
							}
						}
					},
					yAxis: {
						type: 'value',
						minInterval: 1
					},

					series: [
						{
							name: '播放量',
							data: seriesData,
							type: 'line',
							smooth: true,
							itemStyle: {
								color: '#0A88ED',
								lineStyle: {
									color: '#0A88ED'
								}
							},
						}
					],
					grid: {
						left: '25px',
						right: 0,
						top: '50px',
						bottom: '30px'
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						data: ['播放量'],
						top: 0,
						right: 0,
						itemWidth: 40,
						itemHeight: 14
					},
				}
			})

		})
	}

	/**
	 * 获取品牌下的产品列表
	 * @param 
	 * @return
	 */
	getProductList() {
		this.setState({
			isLoad: true
		}, () => {
			requestGetProductList({
				brandId: 0,
				pageSize: this.state.pageInfo.pageSize,
				current: this.state.pageInfo.page
			}).then((res) => {
				const pageInfo = { ...this.state.pageInfo }
				pageInfo.total = res.Data.total
				this.setState({
					productList: res.Data.data,
					pageInfo: pageInfo,
					isLoad: false
				})
			}).catch(() => {
				this.setState({
					productList: [],
					isLoad: false
				})
			})
		})
	}

	/**
	 * 处理时间选择内容变化
	 * @param 
	 * @return
	 */
	handleChange(event: Moment | null, type: "startDate" | "endDate") {
		const dates = this.state.dates
		if (type === 'startDate') {
			dates[0] = event as any
		} else if (type === 'endDate') {
			dates[1] = event as any
		}
		if (dates[0] && dates[1]) {
			this.getDayDataList()
		}
		this.setState({
			dates: dates
		})
	}

	/**
	 * 处理页码改变的回调
	 * @param 
	 * @return
	 */
	handlePaginationChange(page: number, pageSize?: number) {
		const pageInfo = { ...this.state.pageInfo }
		pageInfo.page = page
		this.setState({
			pageInfo: pageInfo
		}, () => {
			this.getProductList()
		})
	}

	/**
	 * 处理点击产品
	 * @param 
	 * @return
	 */
	handleClickProduct(productItem: any) {
		this.props.history?.push({
			pathname: '/main/customer/product', state: {
				productId: productItem.productId,
				brandId: 0   //this.state.brandInfo.brandId
			}
		})
	}

	/**
	 * 渲染产品项
	 * @param 
	 * @return
	 */
	renderProductItem(productItem: any, productKey: number) {
		return <div className='customerHome-item' key={productKey}>
			<div className='customerHome-item-content' onClick={() => { this.handleClickProduct(productItem) }}>
				<img src={productItem.productCoverImg || commonOccupationBitmapImg} alt="" />
				<div className='customerHome-item-textBox'>
					<p className='customerHome-item-title text_ellipsis'>{productItem.productName}</p>
					<div className='customerHome-item-brand'>
						<div className='text_ellipsis'>品牌：{productItem.brandName}</div>
						<img src={productItem.brandCoverImg} alt="" />
					</div>
					<div className='customerHome-item-count'>
						<div>
							<div className='customerHome-item-count-content'>
								<p>{productItem.taskNum}</p>
								<p>模板数</p>
							</div>
						</div>
						<div>
							<div className='customerHome-item-count-content'>
								<p>{productItem.videoNum}</p>
								<p>作品量</p>
							</div>
						</div>
						<div>
							<div className='customerHome-item-count-content'>
								<p>{replaceNumbeToW(productItem.playNum)}</p>
								<p>播放量</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}

	render() {
		return (
			<div className="customerHome">
				<div className='customerHome-title'>
					<div className='customerHome-title-data'>
						<div className='customerHome-title-data-list'>
							<div className='customerHome-title-data-item'>
								<div className='customerHome-title-data-item-content'>
									<img src={numberWorksImage} alt="" />
									<div className='customerHome-title-data-item-text'>
										<p>{this.state.userInfo.videoNum}</p>
										<p>累计产生作品数</p>
									</div>
								</div>
								<div className='title-data-item-line'></div>
							</div>
							<div className='customerHome-title-data-item'>
								<div className='customerHome-title-data-item-content'>
									<img src={numberPlayImage} alt="" />
									<div className='customerHome-title-data-item-text'>
										<p>{replaceNumbeToW(this.state.userInfo.playNum)}</p>
										<p>累计播放量</p>
									</div>
								</div>
								<div className='title-data-item-line'></div>
							</div>
							<div className='customerHome-title-data-item'>
								<div className='customerHome-title-data-item-content'>
									<img src={playbackPlatformImage} alt="" />
									<div className='customerHome-title-data-item-text'>
										<p style={{ fontSize: '24px' }}>抖音、快手</p>
										<p>作品流量平台</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='customerHome-title-company'>
						<img src={this.state.userInfo.userIcon || defaultLogoImg} alt="" />
						<p>{getUserInfoStorage().companyName}</p>
					</div>
				</div>

				<div className='customerHome-charts'>
					<div className='customerHome-charts-title'>
						<p>播放量</p>
						<div className='customerHome-charts-title-search'>
							<RangePicker label='时间选择' allowClear={false} value={this.state.dates} onChange={(event, type) => { this.handleChange(event, type) }}></RangePicker>
						</div>
					</div>
					<div ref="customerHome-charts" id='customerHome-charts' className='customerHome-charts-content'>
						<ReactEcharts
							option={this.state.echartsOption}
							style={{ padding: '12px 24px 24px', height: '100%' }}
						/>
					</div>
				</div>

				<div className='card'>
					<p className='card-title'>产品列表</p>
					<div className='customerHome-list'>
						{
							this.state.isLoad ? <Spin></Spin> : (
								this.state.productList.length ? this.state.productList.map((productItem, productKey) => {
									return this.renderProductItem(productItem, productKey)
								}) : <Empty></Empty>
							)
						}
					</div>

					{
						this.state.productList.length && !this.state.isLoad ?
							<div className='pagination'>
								<Pagination style={{ float: 'right' }} pageSize={this.state.pageInfo.pageSize} showQuickJumper current={this.state.pageInfo.page} total={this.state.pageInfo.total} onChange={(page, pageSize) => { this.handlePaginationChange(page, pageSize) }} />
							</div> : ''
					}
				</div>
			</div>
		)
	}
}