import { EmptyProps, EmptyState } from './data'
import React, { Component } from 'react';
import defaultNoDataImg from '@/components/assets/image/empty/default_img_no_data.png'

import './empty.scss'

export default class EmptyComponent extends Component<EmptyProps, EmptyState> {
	constructor(props: EmptyProps = {
		desc: ''
	}) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {

	}




	render() {
		return (
			<div className='dz-empty'>
				<img className='dz-empty-img' src={defaultNoDataImg} alt="" />
				<div className='dz-empty-desc'>{ this.props.desc || '目前暂无数据' }</div>
			</div>
		)
	}
}