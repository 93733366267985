/**
 * @author sudong.duan
 * Date: 21-12-16
 * desc: 公共校验方法
 */

/**
    校验手机号
    @param {String} phone 手机号
    @return {Boolean} 是否校验通过
*/
const validPhone = (phone: string):boolean => {
	if (/^(1)[0-9]{10}$/.test(phone)) {
		return true
	}
	return false
}


/**
    * 密码的校验函数 6-16位纯数字
    * @param { String } val 待校验的数据
    * @return { Boolean } 校验是否通过
*/
const validPassword = (val: string): boolean => {
	return /^[0-9]{6,16}$/.test(val)
}

/**
    * 验证码 6位纯数字
    * @param { String } val 待校验的数据
    * @return { Boolean } 校验是否通过
*/
const validVerCode = (val: string): boolean => {
	return /^[0-9]{6}$/.test(val)
}

/**
    校验邮箱
    @param {String} email 邮箱
    @return {Boolean} 是否校验通过
*/
const validEmail = (email: string):boolean => {
	if (new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$").test(email)) {
		return true
	}
	return false
}

export {
	validPhone,
	validPassword,
	validVerCode,
	validEmail
}