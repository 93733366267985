import React from 'react';
import './index.scss'
import { LoginProps, LoginState } from './data'
import { requestLogin } from './service'
import Input from './components/input/input'
import { isEmpty } from '@/utils/common';
import { validEmail } from '@/utils/reg'
import { setTokenStorage, setUserInfoToken  } from '@/utils/storage'
import { message } from 'antd';


export default class Login extends React.Component<LoginProps, LoginState> {

    constructor(props: LoginProps = {}) {
        super(props);
        this.state = {
            loginData: {
                email: '',
                passwd: ''
            },
            passwdInputType: 'password',
            errorText: '',
            isShowError: false
        }
    }



    componentDidMount() {

    }


    /**
	 * 处理点击输入框尾部图标
	 * @param 
	 * @return
	 */
    handleSuffix() {
        this.setState({
            passwdInputType: this.state.passwdInputType === 'password' ? 'text' : 'password'
        })
    }

    /**
	 * 处理输入框输入内容改变事件
	 * @param 
	 * @return
	 */
    handleChange(event: string, type: string) {
        const loginData: any = this.state.loginData
        loginData[type] = event
        this.setState({
            loginData: loginData
        })
    }

    /**
	 * 处理输入框获取焦点事件
	 * @param 
	 * @return
	 */
    handleFocus(errorType: 'email' | 'passwd') {
        if (errorType === this.state.errorType) {
            this.setState({
                isShowError: false
            })
        }
    }

    /**
	 * 点击登录按钮触发
	 * @param 
	 * @return
	 */
    goLogin() {
        const loginData = { ...this.state.loginData }
        new Promise((resolve, reject) => {
            if (isEmpty(loginData.email)) {
                reject({
                    type: 'email',
                    message: '用户名为空，请填写用户名'
                })
            }
            if (!validEmail(loginData.email)) {
                reject({
                    type: 'email',
                    message: '请填写正确的用户名'
                })
            }
            if (isEmpty(loginData.passwd)) {
                reject({
                    type: 'passwd',
                    message: '密码为空，请填写密码'
                })
            }
            resolve(true)
        }).then(() => {
            requestLogin({
                passwd: (window as any).md5(loginData.passwd),
                email: loginData.email
            }).then((res) => {
                message.success('登录成功')
                setTokenStorage(res.Data.token)
                setUserInfoToken(res.Data)
                this.props.history.push('/main/customer/home')
            })
        }).catch((error: any) => {
            this.setState({
                errorText: error.message,
                isShowError: true,
                errorType: error.type
            });
            (this.refs[error.type] as any).setError(true)
        })
    }

    render() {
        return (
            <div className="login">
                <div className='login-box radius-default'>
                    <div className='login-left'>
                        <div className='login-left-top'>
                            <p>累积合作客户</p>
                            <p>1000+</p>
                            <p>品牌商</p>
                        </div>
                        <div className='login-left-bottom'>
                            <p>累积入驻达人</p>
                            <p>2W+</p>
                            <p>覆盖抖音、快手、小红书等平台</p>
                        </div>
                    </div>
                    <div className='login-right'>
                        <p className='login-righ-title'>新媒体内容一站式服务平台</p>
                        <p className='login-righ-subTitle'>针对短视频、图文等创意生产环节提供制作服务</p>
                        <div className='login-right-input'>
                            <Input ref="email" placeholder='用户名' value={ this.state.loginData.email } onChange={(event)=> { this.handleChange(event, 'email') }} onFocus={ ()=> { this.handleFocus('email') } }></Input>
                        </div>
                        <div className='login-right-input login-right-password'>
                            <Input ref="passwd" placeholder='密码' onSuffix={()=> { this.handleSuffix() }} isSuffix value={ this.state.loginData.passwd } inputType={ this.state.passwdInputType } onChange={(event)=> { this.handleChange(event, 'passwd') }} onFocus={ ()=> { this.handleFocus('passwd') } }></Input>
                        </div>

                        <div className='login-right-btn' onClick={() => { this.goLogin() }}>登录</div>
                        
                        <div className={`login-right-error ${this.state.isShowError ? '' : 'login-right-error-hide'}`}>{this.state.errorText}</div>
                        
                        <div className='login-right-bottom'>登录即表明同意<a href='https://appstatic.dmpdsp.com/h5/douzhuan/agreement.html' target="_blank">服务协议</a>和<a href='https://appstatic.dmpdsp.com/h5/douzhuan/privacy.html' target="_blank">隐私政策</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}