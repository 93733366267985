import { InputProps, InputState } from './data'
import React, { Component } from 'react';
import visiblePasswordImg from '@/assets/sys/login/password_visible.png'
import invisiblePasswordImg from '@/assets/sys/login/password_invisible.png'
import './input.scss'

export default class InputComponent extends Component<InputProps, InputState> {
	constructor(props: InputProps = {
		value: ''
	}) {
		super(props);
		this.state = {
			isError: false
		}
	}

	componentDidMount() {

	}

	/**
	 * 输入框内容改变时触发
	 * @param 
	 * @return
	 */
	handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (this.props.onChange && typeof this.props.onChange === 'function') {
			this.props.onChange(event.target.value)
		}
	}

	/**
	 * 输入框设置错误状态
	 * @param 
	 * @return
	 */
	setError(isError: boolean) {
		this.setState({
			isError: isError
		})
	}

	/**
	 * 输入框获取焦点时触发
	 * @param 
	 * @return
	 */
	handleFocus() {
		if (this.props.onFocus && typeof this.props.onFocus === 'function') {
			this.props.onFocus()
		}
		this.setState({
			isError: false
		})
	}

	/**
	 * 处理点击输入框尾部图标
	 * @param 
	 * @return
	 */
	handleSuffix() {
		if (this.props.onSuffix && typeof this.props.onSuffix === 'function') {
			this.props.onSuffix()
		}
	}

	render() {
		return (
			<div className={ `dz-input ${this.state.isError ? 'dz-input-error' : ''}` }>
				<input type={this.props.inputType || 'text'} value={this.props.value} placeholder={this.props.placeholder} onChange={(event) => { this.handleChange(event) }} onFocus={()=> { this.handleFocus() }} />
				{
					this.props.isSuffix ? <div className='dz-input-suffix'  onClick={()=> { this.handleSuffix() }}>
						{
							this.props.inputType === 'password' ? <img src={visiblePasswordImg} alt="" /> : <img src={invisiblePasswordImg} alt="" />
						}
					</div> : ''
				}
			</div>
		)
	}
}