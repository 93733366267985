import advantage1Image from '@/assets/sys/official/advantage-1.png'
import advantage2Image from '@/assets/sys/official/advantage-2.png'
import advantage3Image from '@/assets/sys/official/advantage-3.png'
import advantage4Image from '@/assets/sys/official/advantage-4.png'
import advantage5Image from '@/assets/sys/official/advantage-5.png'
import advantage6Image from '@/assets/sys/official/advantage-6.png'
import No1Image from '@/assets/sys/official/No1.png'
import No2Image from '@/assets/sys/official/No2.png'
import No3Image from '@/assets/sys/official/No3.png'
import No4Image from '@/assets/sys/official/No4.png'
import No5Image from '@/assets/sys/official/No5.png'
import marke1Image from '@/assets/sys/official/marke_1.png'
import marke2Image from '@/assets/sys/official/marke_2.png'
import marke3Image from '@/assets/sys/official/marke_3.png'
import marke4Image from '@/assets/sys/official/marke_4.png'
import marke5Image from '@/assets/sys/official/marke_5.png'
import productAPPIcon1Image from '@/assets/sys/official/pptg.png'
import productAPPIcon2Image from '@/assets/sys/official/hwtg.png'
import productAPPIcon3Image from '@/assets/sys/official/jtk.png'
import productAPP1Image from '@/assets/sys/official/product-app-1.png'
import productAPP2Image from '@/assets/sys/official/product-app-2.png'
import productAPP3Image from '@/assets/sys/official/product-app-3.png'
import productPCIcon1Image from '@/assets/sys/official/rwfbpt.png'
import productPCIcon2Image from '@/assets/sys/official/supt.png'
import productPCIcon3Image from '@/assets/sys/official/fzpxt.png'
import productPC1Image from '@/assets/sys/official/product-pc-1.png'
import productPC2Image from '@/assets/sys/official/product-pc-2.png'
import productPC3Image from '@/assets/sys/official/product-pc-3.png'
import case1Image from '@/assets/sys/official/case_1.png'
import case2Image from '@/assets/sys/official/case_2.png'
import case3Image from '@/assets/sys/official/case_3.png'

import { validPhone } from '@/utils/reg'

const tabbarList = [
	{
		label: '首页',
		href: '#'
	},
	{
		label: '平台优势',
		href: '#advantage'
	},
	{
		label: '产品介绍',
		href: '#product'
	},
	{
		label: '营销能力',
		href: '#marke'
	},
	{
		label: '优质案例',
		href: '#case'
	},
	{
		label: '商务合作',
		href: '#cooperation'
	}
]

const swiperList = [
	{
		img: advantage1Image,
		title: '流量',
		subTitle: '低成本公域流量获取能力'
	},
	{
		img: advantage2Image,
		title: '内容',
		subTitle: '可定制的内容创作能力'
	},
	{
		img: advantage3Image,
		title: '数据',
		subTitle: '曝光传播销售全链路数据能力'
	},
	{
		img: advantage4Image,
		title: '服务',
		subTitle: '基于内容生产和品牌营销的服务团队'
	},
	{
		img: advantage5Image,
		title: '传播',
		subTitle: '多点分布式短视频传播'
	},
	{
		img: advantage6Image,
		title: '达人',
		subTitle: '优质中长尾达人招募能力'
	}
]

const productTabbarList = [
	{
		label: '斗转APP'
	},
	{
		label: '斗转星图'
	}
]

const productAPPRenderList = [
	{
		icon: productAPPIcon1Image,
		title: '品牌推广',
		subTitle: '涨粉/赚钱通通有，更多合适的任务',
		img: productAPP1Image
	},
	{
		icon: productAPPIcon2Image,
		title: '好物推广',
		subTitle: '小黄车开橱窗，报名赚取更多收益',
		img: productAPP2Image
	},
	{
		icon: productAPPIcon3Image,
		title: '剪同款',
		subTitle: '脚本和目标定制，海量模板等你来任务',
		img: productAPP3Image
	}
]

const productPCRenderList = [
	{
		icon: productPCIcon1Image,
		title: '任务发布平台',
		subTitle: '斗转星图一站式服务任务发布更简单',
		img: productPC1Image
	},
	{
		icon: productPCIcon2Image,
		title: '数据平台',
		subTitle: '曝光传播销售全链路数据分析',
		img: productPC2Image
	},
	{
		icon: productPCIcon3Image,
		title: '实时反作弊监控',
		subTitle: '实时监控作弊任务，无所遁形',
		img: productPC3Image
	}
]

const markeRenderList = [
	{
		serialImage: No1Image,
		image: marke1Image,
		title: '品牌曝光',
		subTitle: '植入品牌广告'
	},
	{
		serialImage: No2Image,
		image: marke2Image,
		title: '主题传播',
		subTitle: '创建话题海量传播'
	},
	{
		serialImage: No3Image,
		image: marke3Image,
		title: '内容带货',
		subTitle: '内容平台小黄车、橱窗带货'
	},
	{
		serialImage: No4Image,
		image: marke4Image,
		title: '直播预约',
		subTitle: '海量曝光直播间预告及预约'
	},
	{
		serialImage: No5Image,
		image: marke5Image,
		title: '私域吸粉',
		subTitle: '内容脚本定向线下吸粉'
	}
]

const caseSwiperList = [
	{
		img: case1Image,
		title: '翠绿珠宝',
		subTitle: '翠绿制造，追求卓越是翠绿十多年来企业发展的基石；打造民族首饰文化经典品牌是翠绿首饰不懈的追求与前进的方向。',
		playNum: '350.5',
		peopleNum: '1980',
		productionNum: '2346',
		desc: '作品流量平台：抖音、快手等'
	},
	{
		img: case2Image,
		title: '人民小酒',
		subTitle: '岩博酒业企业核心文化——大山文化，岩博的“大山情怀”是朴实、谦逊、深沉、稳重、底蕴丰厚、质感饱满的，岩博的热情好客、开拓进取、继往开来又像大海一样宽广和包容。',
		playNum: '1059',
		peopleNum: '1644',
		productionNum: '4308',
		desc: '作品流量平台：抖音、快手等'
	},
	{
		img: case3Image,
		title: '欧莱雅',
		subTitle: '巴黎欧莱雅中国官方网站，源自法国的知名国际品牌，专业提供全方位女士/男士美肤美发明星产品及方案，立即访问欧莱雅官网，你值得拥有!',
		playNum: '746',
		peopleNum: '1020',
		productionNum: '2196',
		desc: '作品流量平台：抖音、快手等'
	}
]

const professionOptions = [
	{
		label: '餐饮',
		value: '餐饮'
	},
	{
		label: '美业',
		value: '美业'
	},
	{
		label: '服饰',
		value: '服饰'
	},
	{
		label: '网服',
		value: '网服'
	},
	{
		label: '文化轻工',
		value: '文化轻工'
	},
	{
		label: '零售',
		value: '零售'
	},
	{
		label: '金融',
		value: '金融'
	},
	{
		label: '旅游',
		value: '旅游'
	},
	{
		label: '广告传媒',
		value: '广告传媒'
	},
	{
		label: '电子科技',
		value: '电子科技'
	},
	{
		label: '汽车相关',
		value: '汽车相关'
	},
	{
		label: '机械化工',
		value: '机械化工'
	},
	{
		label: '生物健康',
		value: '生物健康'
	},
	{
		label: '能源/环保',
		value: '能源/环保'
	},
	{
		label: '印刷物流',
		value: '印刷物流'
	},
	{
		label: '其他',
		value: '其他'
	}
]

const formRenderList = [
	{
		label: '企业名称',
		value: 'companyName',
		placeholder: '请输入'
	},
	{
		label: '姓名',
		value: 'userName',
		placeholder: '请输入'
	},
	{
		label: '手机号',
		value: 'mobile',
		placeholder: '请输入',
		vaildFnc: validPhone
	},
	{
		label: '地区',
		value: 'address',
		placeholder: '请选择'
	},
	{
		label: '行业',
		value: 'profession',
		placeholder: '请选择'
	},
	{
		label: '推广产品介绍',
		value: 'productIntroduce',
		placeholder: '请输入'
	}
]


export {
	tabbarList,
	swiperList,
	productTabbarList,
	markeRenderList,
	caseSwiperList,
	professionOptions,
	formRenderList,
	productAPPRenderList,
	productPCRenderList
}