import { SpinProps, SpinState } from './data'
import React, { Component } from 'react';
import { Spin } from 'antd';
import './spin.scss'

export default class SpinComponent extends Component<SpinProps, SpinState> {
	constructor(props: SpinProps = {
		
	}) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {

	}




	render() {
		return (
			<div className='dz-Spin'>
				<div className='dz-Spin-icon'>
					<Spin size="large" />
				</div>
				<div className='dz-Spin-tip'>加载中...</div>
			</div>
		)
	}
}