// 登录
import Login from "@/pages/sys/login";

// 官网页面
import Official from "@/pages/sys/official";


// 布局页面
import Main from '@/pages/main/index'

//客户首页
import CustomerHome from '@/pages/main/customer/home/home'

//产品详情页
import CustomerProduct from '@/pages/main/product/product'

//任务详情页
import CustomerTask from '@/pages/main/task/task'





interface router {
    path: string,
    component: any,
    exact?: boolean,
    children?: Array<router>
}

const routers: Array<router> = [
    {
        path: '/main',
        component: Main,
        children: [
            {
                path: '/main/customer/home',
                component: CustomerHome
            },
            {
                path: '/main/customer/product',
                component: CustomerProduct
            },
            {
                path: '/main/customer/task',
                component: CustomerTask
            }
        ],
        //beforeEnter
    },
    {
        path: '/official',
        component: Official,
        exact: true
    },
    {
        path: '/',
        component: Login,
        exact: true
    }
]
export default routers