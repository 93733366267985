import { RangePickerProps, RangePickerState } from './data'
import React, { Component } from 'react';
import { DatePicker } from 'antd'
import arrowImg from '@/components/assets/image/rangerPicker/arrow.png'
import moment, { Moment } from 'moment';
import './rangePicker.scss'

export default class RangePickerComponent extends Component<RangePickerProps, RangePickerState> {
	constructor(props: RangePickerProps = {
		value: []
	}) {
		super(props);
		this.state = {
			size: 'large'
		}
	}

	componentDidMount() {
		if (this.props.size) {
			this.setState({
				size: this.props.size
			})
		}
	}

	/**
     * 处理时间选择器获取焦点事件事件
     * @param 
     * @return
     */
	handleFocus() {

	}

	/**
     * 处理时间选择器失去焦点事件事件
     * @param 
     * @return
     */
	handleBlur() {

	}

	/**
     * 处理日期选择器弹出和关闭
     * @param 
     * @return
     */
	handleOpen(event: boolean, type: keyof RangePickerState) {
		this.setState({
			[type]: event
		} as Pick<RangePickerState, typeof type>)
	}

	/**
     * 处理日期改变事件
     * @param 
     * @return
     */
	handleChange(event: Moment | null, type: 'startDate' | 'endDate') {
		if (this.props.onChange && typeof this.props.onChange === 'function') {
			this.props.onChange(event, type)
		}
	}

	render() {
		return (
			<div className="ml-rangePicker" >
				<label >{ this.props.label }：</label>
				<DatePicker style={{width: this.props.width}}
					allowClear={ this.props.allowClear }
					value={this.props.value[0]}
					size={this.state.size}
					showToday={ false }
					placeholder={this.props.startDatePlaceholder || '开始日期'}
					suffixIcon={<img className={`ml-rangePicker-arrow ${this.state.isFocusStartDate ? 'ml-rangePicker-arrow-focus' : ''}`} src={arrowImg} alt="" />}
					disabledDate={(current) => { 
						if (this.props.value[1]) {
							if (moment(current).valueOf() > moment(this.props.value[1]).valueOf()) {
								return true
							} else {
								return false
							}
						} else {
							return false
						}
					 } }
					onOpenChange={(event) => { this.handleOpen(event, 'isFocusStartDate') }}
					onChange={ (event)=> { this.handleChange(event, 'startDate') } }
				></DatePicker>
				<span className='ml-rangePicker-separator'>{ this.props.separator || '-' }</span>
				<DatePicker style={{width: this.props.width}}
					allowClear={ this.props.allowClear }
					value={this.props.value[1]}
					size={this.state.size}
					showToday={ false }
					placeholder={this.props.startDatePlaceholder || '结束日期'}
					suffixIcon={<img className={`ml-rangePicker-arrow ${this.state.isFocusEndDate ? 'ml-rangePicker-arrow-focus' : ''}`} src={arrowImg} alt="" />}
					disabledDate={(current) => { 
						if (this.props.value[0]) {
							if (moment(current).valueOf() < moment(this.props.value[0]).valueOf()) {
								return true
							} else {
								return false
							}
						} else {
							return false
						}
					 } }
					onOpenChange={(event) => { this.handleOpen(event, 'isFocusEndDate') }}
					onChange={ (event)=> { this.handleChange(event, 'endDate') } }
				></DatePicker>
			</div>
		) 
	}
}